import React, { Component } from "react";
import { Button, Col, Row, Card, Table, Container } from "react-bootstrap";
import configData from "../config.json";
import { toast } from "react-toastify";
import "./Proposerinfo.css";

export default class AddOns extends Component {
  constructor(props) {
    super(props);
    this.state = {
      chpiId: sessionStorage.getItem("chpiId"),
      proposalId: sessionStorage.getItem("proposalId"),
      baseproductId: sessionStorage.getItem("baseproductId"),
      cqrId: sessionStorage.getItem("cqrId"),
      policiesTable: [],
      mandatoryaddons: [],
      optionaladdons: [],
      defalutaddons: [],
      showExistingSince: {},
      isSameAsPermanent: false,
      dateValues: {},
    };
  }
  showToast = (msg, type) => {
    var tType = toast.TYPE.INFO;
    if (type === "success") tType = toast.TYPE.SUCCESS;
    if (type === "error") tType = toast.TYPE.ERROR;
    if (type === "warning") tType = toast.TYPE.WARNING;
    toast(msg, { type: tType });
  };
  handleSubmitClick = (e) => {
    window.location.href = "/nomineedetails";
  };
  componentDidMount() {
    this.apisfetchHandler();
  }
  apisfetchHandler = () => {
    var formData = new FormData();
    formData.append("proposalId", this.state.proposalId);
    formData.append("baseproductId", this.state.baseproductId);
    fetch(configData.api_url + "leads/getleaddetails/", {
      method: "POST",
      mode: "cors",
      body: formData,
    })
      .then((response) => response.json())
      .then((data) => {
        this.showToast(data.message, data.success);
        if (data.status === "success") {
          this.setState({ members: data.lead.members });
          this.CaresupremeaddonsfetchHandler();
        }
      });
  };
  CaresupremeaddonsfetchHandler = () => {
    var formData = new FormData();
    formData.append("chpiId", this.state.chpiId);
    formData.append("baseproductId", this.state.baseproductId);
    fetch(configData.api_url + "leads/getproductdetails", {
      method: "POST",
      mode: "cors",
      body: formData,
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.status === "success") {
          this.setState({ mandatoryaddons: data.product.mandatory_addons });
          this.setState({ optionaladdons: data.product.optional_addons });
          this.getDefalutAddOns();
        }
      });
  };

  getDefalutAddOns = () => {
    var formData = new FormData();
    formData.append("chpiId", this.state.chpiId);
    formData.append("proposalId", this.state.proposalId);
    formData.append("baseproductId", this.state.baseproductId);
    fetch(configData.api_url + "leads/getdefaultAddons", {
      method: "POST",
      mode: "cors",
      body: formData,
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.status === "success") {
          this.setState({ defalutaddons: data.defalutaddons });
          var disabledAddons = [];
          var optionaladdons = this.state.optionaladdons;
          var defalutaddons = this.state.defalutaddons;
          optionaladdons.forEach((quote) => {
            quote.isDefault = "no";
            if (defalutaddons.includes(quote.addONCode)) {
              quote.isMandatory = "yes";
              quote.isDefault = "yes";
              quote.isdisable = "yes";
              disabledAddons = quote.disabledAddons.split(",");
            }
          });
          this.setState({ optionaladdons: optionaladdons });
          this.setState((prevState) => {
            const updatedAddonsData = prevState.optionaladdons.map((quote) => {
              var isdisable = "no";
              if (
                disabledAddons.includes(quote.addONCode) ||
                defalutaddons.includes(quote.addONCode)
              ) {
                isdisable = "yes";
              }
              return { ...quote, isdisable: isdisable };
            });
            return { optionaladdons: updatedAddonsData };
          });
        }
      });
  };

  handleInputChange = (tab, field, value) => {
    this.setState((prevState) => ({
      [tab]: {
        ...prevState[tab],
        [field]: value,
      },
    }));
  };
  handleSubmitBackClick = (e) => {
    window.location.href = "/nomineedetails";
  };
  handleAddonsCheckboxChange = (addONCode, caresupremeaddons_Id, isChecked) => {
    this.setState((prevState) => {
      const updatedAddonsData = prevState.mandatoryaddons.map((quote) => {
        if (quote.addONCode === addONCode) {
          return { ...quote, isMandatory: isChecked ? "yes" : "no" };
        }
        return quote;
      });
      return { mandatoryaddons: updatedAddonsData };
    });
  };

  handleOptionalCheckboxChange = (
    addONCode,
    caresupremeaddons_Id,
    isChecked
  ) => {
    this.setState((prevState) => {
      var disabledAddons = [];
      const updatedAddonsData = prevState.optionaladdons.map((quote) => {
        if (quote.addONCode === addONCode) {
          quote.isMandatory = isChecked ? "yes" : "no";
          quote.isDefault = "no";
          // return { ...quote, isMandatory: isChecked ? "yes" : "no" };
        }
        if (quote.isMandatory === "yes") {
          var disabledAddons1 = quote.disabledAddons.split(",");
          // console.log(disabledAddons1);
          disabledAddons1.forEach((element) => {
            disabledAddons.push(element);
          });
          // console.log(disabledAddons);
        }
        console.log(quote);
        return quote;
      });
      updatedAddonsData.map((quote) => {
        var isdisable = "no";
        if (
          disabledAddons.includes(quote.addONCode) ||
          (quote.isMandatory === "yes" &&
            quote.disabledAddons !== "" &&
            quote.isDefault === "yes") ||
          quote.isDefault === "yes"
        ) {
          isdisable = "yes";
        }
        quote.isdisable = isdisable;
        return quote;
      });
      return { optionaladdons: updatedAddonsData };
    });
  };

  handleComplete = (e) => {
    const selectedAddons = [
      ...this.state.mandatoryaddons,
      ...this.state.optionaladdons,
    ];

    const selectedAddonsList = selectedAddons.map((quote) => ({
      aoId: quote.caresupremeaddons_Id,
      aoCode: quote.addONCode,
      isChecked: quote.isMandatory,
    }));

    const formData = new FormData();
    formData.append("proposalId", this.state.proposalId);
    formData.append("baseproductId", this.state.baseproductId);
    formData.append("addons", JSON.stringify(selectedAddonsList));
    fetch(configData.api_url + "leads/updateleadaddons/", {
      method: "POST",
      headers: {},
      mode: "cors",
      body: formData,
    })
      .then((response) => response.json())
      .then((data) => {
        this.showToast(data.message, data.status);
        if (data.status === "success") {
          this.createPolicy();
        }
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  };
  createPolicy() {
    const formData = new FormData();
    formData.append("proposalId", this.state.proposalId);
    formData.append("baseproductId", this.state.baseproductId);
    formData.append("chpiId", this.state.chpiId);
    formData.append("cqrId", this.state.cqrId);
    fetch(configData.api_url + "leads/createpolicy/", {
      method: "POST",
      headers: {},
      mode: "cors",
      body: formData,
    })
      .then((response) => response.json())
      .then((data) => {
        this.showToast(data.message, data.status);
        // alert(JSON.stringify(data.policy));
        if (data.status === "success") {
          sessionStorage.setItem(
            "proposalNum",
            data.policy.intPolicyDataIO.policy.proposalNum
          );
          window.location.href = "/proposalsummary";
        }
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  }
  render() {
    return (
      <Container className="bgalphacolor">
        <Row id="MainRoOne">
          <Col>
            <Row>
              <Col xs={10} md={10} lg={8}>
                <Card id="firstCarOne">
                  <Row id="firstRoTwo">
                    <Col id="firstColTh3">
                      <span
                        className="bi bi-person-fill"
                        id="activeIcon"
                      ></span>
                    </Col>
                  </Row>
                  <Row>
                    <div>
                      <h6 style={{ color: "#3d5a95" }}>Proposer Info</h6>
                    </div>
                  </Row>
                </Card>
              </Col>
              <Col xs={2} md={2} lg={2}>
                <span
                  className="bi bi-arrow-right"
                  id="activeTitileArrow"
                ></span>
              </Col>
            </Row>
          </Col>
          <Col>
            <Row>
              <Col xs={10} md={10} lg={8}>
                <Card id="firstCarOne">
                  <Row id="firstRoTwo">
                    <Col id="firstColTh3">
                      <span
                        className="bi bi-person-vcard-fill"
                        id="activeIcon"
                      ></span>
                    </Col>
                  </Row>
                  <Row>
                    <div>
                      <h6 style={{ color: "#3d5a95" }}>Member Info</h6>
                    </div>
                  </Row>
                </Card>
              </Col>
              <Col xs={2} md={2} lg={2}>
                <span
                  className="bi bi-arrow-right"
                  id="activeTitileArrow"
                ></span>
              </Col>
            </Row>
          </Col>
          <Col>
            <Row>
              <Col xs={10} md={10} lg={8}>
                <Card id="firstCarOne">
                  <Row id="firstRoTwo">
                    <Col id="firstColTh3">
                      <span
                        className="bi bi-heart-pulse"
                        id="activeIcon"
                      ></span>
                    </Col>
                  </Row>
                  <Row>
                    <div>
                      <h6 style={{ color: "#3d5a95" }}>Medicals</h6>
                    </div>
                  </Row>
                </Card>
              </Col>
              <Col xs={2} md={2} lg={2}>
                <span
                  className="bi bi-arrow-right"
                  id="activeTitileArrow"
                ></span>
              </Col>
            </Row>
          </Col>
          <Col>
            <Row>
              <Col xs={10} md={10} lg={8}>
                <Card id="firstCarOne">
                  <Row id="firstRoTwo">
                    <Col id="firstColTh3">
                      <span
                        className="bi bi-pencil-square"
                        onClick={this.handleSubmitClick}
                        id="activeIcon"
                      ></span>
                    </Col>
                  </Row>
                  <Row>
                    <div>
                      <h6
                        style={{ color: "#3d5a95" }}
                        onClick={this.handleSubmitClick}
                      >
                        Nominee Details
                      </h6>
                    </div>
                  </Row>
                </Card>
              </Col>
              <Col xs={2} md={2} lg={2}>
                <span
                  className="bi bi-arrow-right"
                  id="activeTitileArrow"
                ></span>
              </Col>
            </Row>
          </Col>
          <Col>
            <Row>
              <Col xs={10} md={10} lg={8}>
                <Card id="firstCarOne">
                  <Row id="firstRoTwo">
                    <Col id="firstColTh3">
                      <span
                        className="bi bi-clipboard-plus"
                        id="activeIcon"
                      ></span>
                    </Col>
                  </Row>
                  <Row>
                    <div>
                      <h6 style={{ color: "#3d5a95" }}>Addon's</h6>
                    </div>
                  </Row>
                </Card>
              </Col>
              <Col xs={2} md={2} lg={2}></Col>
            </Row>
          </Col>
        </Row>
        <div>
          <Card>
            <Card.Body>
              <Row>
                <Col>
                  <h3>Mandatory AddOns</h3>
                  <Table className="table table-bordered">
                    <thead>
                      <tr>
                        <th scope="col">S No.</th>
                        <th scope="col">Addon Name</th>
                        <th scope="col">Select</th>
                      </tr>
                    </thead>
                    <tbody>
                      {this.state.mandatoryaddons.map((quote, index) => (
                        <tr key={index}>
                          <td>{index + 1}</td>
                          <td>{quote.addOn}</td>
                          <td>
                            <input
                              type="checkbox"
                              checked={
                                quote.isMandatory === "yes" ? true : false
                              }
                              id="consentCheckbox"
                              name="consentCheckbox"
                              onChange={(e) =>
                                this.handleAddonsCheckboxChange(
                                  quote.addONCode,
                                  quote.caresupremeaddons_Id,
                                  !(quote.isMandatory === "yes" ? true : false)
                                )
                              }
                              disabled={true}
                            />
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </Table>
                  <br />
                  <h3>Optional AddOns</h3>
                  <Table className="table table-bordered">
                    <thead>
                      <tr>
                        <th scope="col">S No.</th>
                        <th scope="col">Addon Name</th>
                        <th scope="col">Select</th>
                      </tr>
                    </thead>
                    <tbody>
                      {this.state.optionaladdons.map((quote, index) => (
                        <tr key={index}>
                          <td>{index + 1}</td>
                          <td>{quote.addOn}</td>

                          <td>
                            <input
                              type="checkbox"
                              checked={
                                quote.isMandatory === "yes" ? true : false
                              }
                              onChange={(e) =>
                                this.handleOptionalCheckboxChange(
                                  quote.addONCode,
                                  quote.caresupremeaddons_Id,
                                  !(quote.isMandatory === "yes" ? true : false)
                                )
                              }
                              disabled={
                                quote.isdisable === "yes" ? true : false
                              }
                            />
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </Table>
                </Col>
              </Row>
            </Card.Body>
          </Card>
          <br />
        </div>
        <div className="row">
          <div className="col">
            <Button
              id="getQuotes4"
              onClick={this.handleSubmitClick}
              type="submit"
              style={{ float: "left" }}
            >
              Back
            </Button>
          </div>
          <div className="col" style={{ float: "right" }}>
            <Button
              id="getQuotes4"
              onClick={this.handleComplete}
              type="submit"
              style={{ float: "right" }}
            >
              Finish
            </Button>
          </div>
        </div>
      </Container>
    );
  }
}
